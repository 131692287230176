<template>
	<div class="event-details ml-12">
		<vs-row vs-justify="center" v-if="!isFetching && !isFetchingParticipant">
			<vs-row vs-justify="center">
				<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/1">
					<div class="flex flex-wrap mb-5 event-user">
						<div class="title-user-events">
							<router-link :to="`/`" class="text-black">
								<feather-icon icon="ArrowLeftIcon" class="inline-block mr-5 pt-4" svgClasses="w-12 h-12" />
							</router-link>
							{{ (eventData && eventData.name) ? eventData.name : '' }}
						</div>
					</div>
				</vs-col>
			</vs-row>
			<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/1">
				<vs-card class="cardx relative event-user">
					<div v-if="(eventData && eventData.feature_image) === null" slot="media" class="thumbnail-event image-upcoming">
						<div class="initial-event">{{ generateInitial(eventData) }}</div>
						<!-- <img src="@/assets/images/logo/default-galleries.png" alt="Galleries" class="responsive card-img-top-upcoming" /> -->
					</div>
					<div v-else slot="media">
						<div class="initial-event"><img :src="eventData.feature_image" alt="img" class="responsive card-img-top-details" /></div>
					</div>
				</vs-card>
				<vs-row vs-justify="center">
					<vs-col type="flex">
						<div class="flex flex-wrap justify-between mt-2 mb-6 event-user">
							<div class="item-date truncate text-black">{{ dateFormat(eventData && eventData.start_time ? eventData.start_time : '' ) }}</div>
							<div v-if="!isFetching && eventData && eventData.__meta__.is_participate !== 1">
								<vs-button v-if="eventData && eventData.is_paid !== 0" color="#4690DE"
									@click="confirmPurchase(eventData && eventData ? eventData : '')"
									class="btn-login-upcoming">{{eventData.currency.toUpperCase()}} {{priceEvent(eventData && eventData.price ? eventData.price : '')}}
								</vs-button>
								<vs-button v-else color="#4690DE"
									@click="buyProcessFree(eventData && eventData ? eventData : '')"
									class="btn-login-upcoming">{{priceEvent(eventData && eventData.price ? eventData.price : '')}}
								</vs-button>
							</div>
							<div v-if="!isFetching && eventData && eventData.__meta__.is_participate === 1">
								<!-- participant -->
								<vs-button v-if="participant.roles !== isModerator" color="#28C76F" @click="openPreview()"
								class="btn-login-upcoming">{{$t('Go to Event')}}
								</vs-button>
								<!-- moderator -->
								<vs-button v-else color="#28C76F" @click="openDetailsEvent()"
								class="btn-login-upcoming">{{$t('Details')}}
								</vs-button>
							</div>
						</div>
					</vs-col>
				</vs-row>
				<section class="event-user" v-html="eventData && eventData.description ? eventData.description : ''">
				</section>
			</vs-col>
		</vs-row>
		<!-- popup confirm purchase -->
		<vs-popup classContent="popup-example"  :title="$t('Confirm Purchase')" :active.sync="confirmPurchaseDialog">
			{{ $t('You have selected event') }} : {{eventData && eventData.name ? eventData.name : ''}}{{$t(' for purchased')}}
			<div v-if="eventData && eventData.is_paid !== 0" class="mt-3">{{$t('Price')}} : {{eventData.currency.toUpperCase()}} {{ priceEvent(eventData && eventData.price ? eventData.price : '') }}</div>
			<h6 class="mt-12 text-blue">{{ $t('You will be redirected to Stripe for payment')}}</h6>
			<vs-divider/>
			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-button color="#B8B8B8" class="mr-3" type="filled" @click="confirmPurchaseDialog = false">{{$t('Cancel')}}</vs-button>
				<vs-button v-if="eventData && eventData.is_paid !== 0" color="primary" type="filled" @click="buyProcessPaid(eventData && eventData ? eventData : '')">{{$t('Confirm')}}</vs-button>
				<vs-button v-else color="primary" type="filled" @click="buyProcessFree(eventData && eventData ? eventData : '')">{{$t('Confirm')}}</vs-button>
			</vs-row>
		</vs-popup>
		<!-- popup completed purchase -->
		<vs-popup classContent="popup-example"  :title="$t('Purchase Completed')" :active.sync="showDialogCompletedPurchase">
			{{ $t('Thank you for your purchased on') }} : {{eventData && eventData.name ? eventData.name : ''}}{{$t(' for purchased')}}
			<h6 class="mt-12 text-black">{{ $t('Payment proof is sent to your registered email')}}</h6>
			<vs-divider/>
			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-button color="#B8B8B8" class="mr-3" type="filled" @click="showDialogCompletedPurchase = false">{{$t('Cancel')}}</vs-button>
				<vs-button color="primary" type="filled" @click="getInvoice(checkoutTransaction.transaction.id)">{{$t('Confirm')}}</vs-button>
			</vs-row>
		</vs-popup>
		<!-- popup stripe purchase -->
		<vs-popup button-close-hidden classContent="popup-example"  :title="$t('Stripe')" :active.sync="showDialogStripePurchase">
			<h6 class="black--text ml-2 mb-4 mt-4 ml-2 mr-2">{{ $t('All payments can only be made by the Card method')}}</h6>
				<h6 class="black--text ml-2 mb-8 mt-4 ml-2 mr-2">{{ $t('The amount you have to pay the Event is')}} {{eventData && eventData.currency.toUpperCase()}} {{eventData && eventData.price ? eventData.price : ''}},
				{{ $t('make sure the amount you pay matches the Event you purchased')}}</h6>
				<div id="card-element" class="ml-2 pb-12">
				</div>
			<vs-divider/>
			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-button color="#B8B8B8" class="mr-3" type="filled" @click="closePayment()">{{$t('Cancel')}}</vs-button>
				<vs-button @click="payingPackage()" color="primary" type="filled">{{$t('Confirm')}}</vs-button>
			</vs-row>
		</vs-popup>
	</div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { mapGetters } from 'vuex';
// import dayjs from 'dayjs';
import eventsApi from '@/api/event';
import transactionsApi from '@/api/transaction';
import participantApi from '@/api/participant';
import {
	dateFormat,
} from '@/lib/helper';

export default {
	name: 'UserEventDetails',
	components: {
	},
	data() {
		return {
			stripeAPIToken: 'pk_test_cEeFbGOnhljGBjRCCjp7h3dL00SPobqhFE',
			isFetching: false,
			eventData: null,
			isSidebarActive: false,
			statisticData: null,
			dataImg: null,
			dataIsPublish: false,
			dataPrice: '',
			dataIsPaid: '',
			dataDescription: '',
			dataStart: '',
			dataEnd: '',
			dataLocation: '',
			dataCode: '',
			allowAccessCode: true,
			confirmPurchaseDialog: false,
			showDialogCompletedPurchase: false,
			showDialogStripePurchase: false,
			configDateTimePicker: {
				enableTime: true,
				dateFormat: 'Y-m-d H:i',
				time_24hr: true,
        minDate: 'today',
			},
			participant: null,
			isFetchingParticipant: false,
			isModerator: '["moderator"]',
			// stripe
			stripe: '',
			elements: '',
			card: '',
			name: '',
			addPaymentStatus: 0,
			addPaymentStatusError: '',
			clientSecret: '',
			checkoutTransaction: [],
		}
	},
	mounted() {
		this.includeStripe('js.stripe.com/v3/', () => {
			this.configureStripe();
		});
	},
	computed: {
		...mapGetters({
			userToken: 'userToken',
			isLoggedIn: 'isLoggedIn',
			user: 'user',
		}),
		slug() {
			return this.$route.params.slug;
		},
	},
	methods: {
		getInvoice(trxId) {
			this.showDialogCompletedPurchase = false;
			this.$router.push(`/transactions/${trxId}`).catch(() => {});
		},
		buyProcessFree() {
			this.$vs.loading();
			this.confirmPurchaseDialog = false;
			const callback = (response) => {
				this.checkoutTransaction = response.data;
				this.loading = false;
				this.showDialogCompletedPurchase = true;
				this.$vs.loading.close();
			};
			const transactionsErrorCallback = (e) => {
			// eslint-disable-next-line
			const response = e.response ? e.response.data : false;
				const message = response ? response.message : '';
				this.$vs.notify({
					title: this.$t('User'),
					text: message,
					iconPack: 'feather',
					icon: 'icon-alert-circle',
					color: 'danger',
				});
				this.$vs.loading.close();
			};
			const params = {
				event_id: this.eventData.id,
				currency: 'nok',
			};
			transactionsApi.checkout(params, callback, transactionsErrorCallback);
		},
		buyProcessPaid() {
			this.$vs.loading();
			this.confirmPurchaseDialog = false;
			this.showDialogStripePurchase = true;
			const callback = (response) => {
				this.checkoutTransaction = response.data;
				this.loading = false;
				this.$vs.loading.close();
			};
			const transactionsErrorCallback = (e) => {
			// eslint-disable-next-line
			const response = e.response ? e.response.data : false;
				const message = response ? response.message : '';
				this.$vs.notify({
					title: this.$t('User'),
					text: message,
					iconPack: 'feather',
					icon: 'icon-alert-circle',
					color: 'danger',
				});
				this.$vs.loading.close();
			};
			const params = {
				event_id: this.eventData.id,
				currency: 'nok',
			};
			transactionsApi.checkout(params, callback, transactionsErrorCallback);
		},
		confirmPurchase(eventId) {
			this.confirmPurchaseDialog = true;
		},
		priceEvent(price) {
			if (price <= 0) {
				return 'Free';
			}
			return price;
		},
		dateFormat(date) {
			return dateFormat(date, 'DD. MM. YYYY HH:mm');
		},
		fetchDetail() {
			this.isFetching = true;
			const callback = (response) => {
				const event = response.data;
				this.eventData = event;
				this.isFetching = false;
				this.setData();
			};
			const errorCallback = () => {
				this.isFetching = false;
			};
			eventsApi.getBySlug(this.slug, callback, errorCallback);
		},
		fetchParticipantMe() {
			if (!this.user) return;
			const params = {
				event_slug: this.slug,
			};
			this.isFetchingParticipant = true;
			const callback = (response) => {
				const participant = response.data;
				this.participant = participant;
				this.isFetchingParticipant = false;
			};
			const errorCallback = () => {
				this.isFetchingParticipant = false;
			};
			participantApi.me(params, callback, errorCallback);
		},
		fetch() {
			this.fetchDetail();
			this.fetchParticipantMe();
		},
		openPreview() {
			const routeData = this.$router.resolve({ name: 'EventLive', params: { eventSlug: this.eventData.slug } });
			window.open(`${routeData.href}`, '_blank');
		},
		openDetailsEvent() {
			const routeData = this.$router.resolve({ name: 'EventDetails', params: { eventSlug: this.eventData.slug } });
			window.open(`${routeData.href}`, '_blank');
		},
		setData() {
			this.dataDescription = this.eventData.description;
			this.dataLocation = this.eventData.location;
			this.dataStart = this.eventData.start_time;
			this.dataEnd = this.eventData.end_time;
			this.dataIsPublish = this.eventData.is_published;
			this.dataIsPaid = this.eventData.is_paid;
			this.dataPrice = this.eventData.price;
			this.dataImg = this.eventData.background;
			this.dataCode = this.eventData.access_code;
		},

		payingPackage() {
			this.stripe.confirmCardPayment(
				this.checkoutTransaction.client_secret, {
					payment_method: {
						card: this.card,
					}
				}
			).then(function(result) {
				if (result.error) {
					this.addPaymentStatusError = result.error.message;
					// this.processingPayment('canceled', this.checkoutTransaction.transaction.transaction_number);
				} else {
					this.card.clear();
					this.name = '';
					this.clientSecret = null;
					this.processingPayment('paid', this.checkoutTransaction.transaction.transaction_number);
				}
			}.bind(this));
		},
		processingPayment(status, trx_number) {
			this.$vs.loading();
			const callback = (response) => {
				this.checkoutTransaction = response.data;
				this.loading = false;
				if (status == 'canceled') {
					this.$vs.notify({
						title: this.$t('Cancel'),
						text: 'Canceling Payment',
						iconPack: 'feather',
						icon: 'icon-alert-circle',
						color: 'success',
					});
					this.showDialogStripePurchase = false;
					this.$vs.loading.close();
					// this.overlay = !this.overlay
				} else {
					this.$vs.notify({
						title: this.$t('Success'),
						text: 'Payment Succesfull',
						iconPack: 'feather',
						icon: 'icon-alert-circle',
						color: 'success',
					});
				this.showDialogCompletedPurchase = true;
				this.showDialogStripePurchase = false;
				this.$vs.loading.close();
				}
			};
			const transactionsErrorCallback = (e) => {
			// eslint-disable-next-line
			const response = e.response ? e.response.data : false;
				const message = response ? response.message : '';
					this.$vs.notify({
						title: this.$t('Failed'),
						text: 'Payment Failed',
						iconPack: 'feather',
						icon: 'icon-alert-circle',
						color: 'danger',
					});
				this.$vs.loading.close();
			};
			const params = {
				transaction_number: trx_number,
				status: status,
			};
			transactionsApi.process(params, callback, transactionsErrorCallback);
		},
		closePayment() {
			this.processingPayment('canceled', this.checkoutTransaction.transaction.transaction_number);
		},
		/*
			Configures Stripe by setting up the elements and
			creating the card element.
		*/
		includeStripe(URL, callback) {
			const documentTag = document; const tag = 'script';
			const object = documentTag.createElement(tag);
			const scriptTag = documentTag.getElementsByTagName(tag)[0];
			object.src = `//${URL}`;
			if (callback) { object.addEventListener('load', (e) => { callback(null, e); }, false); }
			scriptTag.parentNode.insertBefore(object, scriptTag);
		},
		/*
			Configures Stripe by setting up the elements and
			creating the card element.
		*/
		configureStripe() {
			// eslint-disable-next-line no-undef
			this.stripe = Stripe(this.stripeAPIToken);
			this.elements = this.stripe.elements();
			this.card = this.elements.create('card');
			this.card.mount('#card-element');
		},
		generateInitial(event) {
			const words = event ? event.name.split(' ') : 'AN';
			const initial = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
			return initial;
		},
	},
	created() {
		this.fetch();
	},
	watch: {
		user() {
			if (this.user) this.fetchParticipantMe();
		},
	},
};
</script>
