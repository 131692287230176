import client from '../lib/http-client';
import { buildQuery } from '../lib/helper';

const endpoint = '/transactions';

export default {

  // Get by id
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get all
  getAll(params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}?${query}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  checkout(params, cb, errorCb) {
    const url = `${endpoint}/checkout`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  process(params, cb, errorCb) {
    const url = `${endpoint}/process`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },
};
